import {useState} from 'react';
import {useAppSelector} from '../../../../store/appDispatch';
import {selectUser} from '../../../../store/slices/userSessionSlice';
import {disconnectHubSpot, saveHubSpotCredentials} from '../../../../api/hubSpot';
import {AuthResponseType, HubSpotRequest} from '../../../../types/integration';
import {useAlert} from '../../../../context/AlertProvider';
import {ERROR, SUCCESS} from '../../../../constants/common';
import i18n from '../../../../translations/i18n';

const useHubSpotFormController = (reloadIntegrationStatus: () => void) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingDisconnect, setLoadingDisconnect] = useState<boolean>(false);
    const [authResponseType, setAuthResponseType] = useState<AuthResponseType | undefined>(undefined);
    const user = useAppSelector(selectUser);
    const {setAlert} = useAlert();

    const saveHubSpotCredentialsApi = (apiToken: string) => {
        setLoading(true);
        const data: HubSpotRequest = { apiToken: apiToken };

        saveHubSpotCredentials(user.id, data)
            .then((res)=> {
                if (res.status === 401) {
                    setAuthResponseType('authError');
                } else if (!res.error) {
                    setAuthResponseType('success');
                    reloadIntegrationStatus();
                } else {
                    setAuthResponseType('serverError');
                }
            })
            .finally(() => setLoading(false));
    };

    const onHubspotDisconnect = async () => {
        setLoadingDisconnect(true);
        disconnectHubSpot(user.id)
            .then(res => {
                if (!res.error) {
                    reloadIntegrationStatus();
                    setAlert(SUCCESS, i18n.t('modal.disconnectPlatformSuccess', { platform: 'HubSpot' }));
                } else {
                    setAlert(ERROR, i18n.t('messages.internalServerError'));
                }
            })
            .finally(() => setLoadingDisconnect(false));
    };

    return {
        loading,
        loadingDisconnect,
        authResponseType,
        setAuthResponseType,
        saveHubSpotCredentialsApi,
        onHubspotDisconnect
    };
};

export default useHubSpotFormController;