import React, {FC, useEffect, useState} from 'react';
import i18n from '../../../../translations/i18n';
import SettingsBox from '../../SettingsBox';
import {Input} from '../../../common/input/Input';
import BtnContainer from '../../../common/button/BtnContainer';
import {notEmpty, valueOr} from '../../../../utils/common';
import {useDebouncedCallback} from 'use-debounce';
import {API_CALL_LONG_DEBOUNCE_TIME_IN_MS, SECRET_HIDDEN} from '../../../../constants/common';
import styles from './salesforce-connect-form.module.scss';
import { ReactComponent as EyeIcon } from '../../../../assets/icons/eye/eye.svg';
import { ReactComponent as EyeOffIcon } from '../../../../assets/icons/eye/eye-off.svg';
import { ReactComponent as WarningBlueIcon } from '../../../../assets/icons/warning/warning-icon-blue.svg';
import { ReactComponent as WarningIcon } from '../../../../assets/icons/warning/warning.svg';
import {PASSWORD, TEXT} from '../../../../types/input';
import useSalesforceFormController from './useSalesforceFormController';
import InfoModal from '../../../common/infoModal/InfoModal';
import {isHidden} from '../integrationUtils';
import {AuthResponseType} from '../../../../types/integration';
import {WarningModal} from '../../../common/warningModal/WarningModal';
import CustomButton from '../../../common/button/CustomButton';

interface SalesforceConnectFormProps {
    isSalesforceEnabled: boolean;
    onClose: () => void;
    reloadIntegrationStatus: () => void;
}

const SalesforceConnectForm: FC<SalesforceConnectFormProps> = ({
    isSalesforceEnabled, onClose, reloadIntegrationStatus
}) => {
    const {
        loading, loadingDisconnect, salesforceCredentials, saveSalesforceCredentialsApi,
        authResponseType, setAuthResponseType, onSalesforceDisconnect
    } = useSalesforceFormController(isSalesforceEnabled, reloadIntegrationStatus);

    const [instanceUrl, setInstanceUrl] = useState<string>('');
    const [consumerKey, setConsumerKey] = useState<string>('');
    const [consumerSecret, setConsumerSecret] = useState<string>('');
    const [showKey, setShowKey] = useState<boolean>(false);
    const [showSecret, setShowSecret] = useState<boolean>(false);
    const [openDisconnectModal, setOpenDisconnectModal] = useState<boolean>(false);

    const isSaveEnabled = () => {
        const isNotEmpty = notEmpty(instanceUrl) && notEmpty(consumerKey) && notEmpty(consumerSecret);
        if (!isNotEmpty) return false;

        const notChanged = instanceUrl === salesforceCredentials?.instanceUrl &&
            consumerKey === salesforceCredentials?.consumerKey &&
            isHidden(consumerSecret);

        return !notChanged;
    };

    const resetCredentials = () => {
        setInstanceUrl('');
        setConsumerKey('');
        setConsumerSecret('');
    };

    const handleCancelChanges = () => {
        resetCredentials();
        onClose();
    };

    const handleSaveChanges = () => isSaveEnabled() && saveSalesforceCredentialsApi(instanceUrl, consumerKey, consumerSecret);

    const handleSaveChangesDebounced = useDebouncedCallback(() => {
        return handleSaveChanges();
    }, API_CALL_LONG_DEBOUNCE_TIME_IN_MS);

    const getAuthResponseText = (type: AuthResponseType | undefined) => {
        if (!type) return '';
        if (type === 'success') return i18n.t('salesforce.authenticationSuccess');
        if (type === 'authError') return i18n.t('salesforce.authenticationFailed');
        if (type === 'serverError') return i18n.t('messages.internalServerError');
        return '';
    };

    const disconnectFromSalesforce = () => {
        onSalesforceDisconnect()
            .then(() => {
                resetCredentials();
                setOpenDisconnectModal(false);
            });
    };

    useEffect(() => {
        if (!salesforceCredentials) return;

        setInstanceUrl(valueOr(salesforceCredentials.instanceUrl, ''));
        setConsumerKey(valueOr(salesforceCredentials.consumerKey, ''));
        setConsumerSecret(salesforceCredentials.consumerKey ? SECRET_HIDDEN : '');
    }, [salesforceCredentials]);

    return (
        <SettingsBox
            title={i18n.t('salesforce.salesforce')}
            goBack={handleCancelChanges}
            subtitleElement={isSalesforceEnabled &&
                <div className={styles.connected}>
                    {i18n.t('common.connected')}
                </div>
            }
        >
            <div className={styles.desc}>
                <div className={styles.warnIcon}>
                    <WarningBlueIcon />
                </div>
                {i18n.t('salesforce.loginToSalesforce')}
            </div>
            <Input
                label={i18n.t('salesforce.instanceUrl')}
                value={instanceUrl}
                placeholder={'https://'}
                onChange={(e) => setInstanceUrl(e.target.value)}
                className={styles.input}
                required
            />
            <Input
                label={i18n.t('salesforce.consumerKey')}
                icon={showKey ? <EyeIcon /> : <EyeOffIcon />}
                value={consumerKey}
                type={showKey ? TEXT : PASSWORD}
                placeholder={'***********'}
                onChange={(e) => setConsumerKey(e.target.value)}
                onIconPress={() => setShowKey(prevState => !prevState)}
                className={styles.input}
                required
            />
            <Input
                label={i18n.t('salesforce.consumerSecret')}
                icon={showSecret ? <EyeIcon /> : <EyeOffIcon />}
                value={consumerSecret}
                type={showSecret ? TEXT : PASSWORD}
                placeholder={'***********'}
                onChange={(e) => setConsumerSecret(e.target.value)}
                onIconPress={() => setShowSecret(prevState => !prevState)}
                className={styles.input}
                required
            />
            {isSalesforceEnabled &&
                <CustomButton
                    text={i18n.t('common.disconnect')}
                    onClick={() => setOpenDisconnectModal(true)}
                    sx={styles.disconnectBtn}
                />
            }
            <BtnContainer
                onCancel={handleCancelChanges}
                onSave={handleSaveChangesDebounced}
                loading={loading}
                isDisabled={!isSaveEnabled()}
                sx={{cancel: styles.cancelBtn}}
            />
            <InfoModal
                isOpen={authResponseType != undefined}
                onClose={() => setAuthResponseType(undefined)}
                type={authResponseType === 'success' ? 'success' : 'error'}
                title={i18n.t('common.authentication')}
                text={getAuthResponseText(authResponseType)}
            />
            <WarningModal
                isOpen={openDisconnectModal}
                icon={<WarningIcon />}
                title={i18n.t('modal.disconnectPlatformTitle', { platform: 'Salesforce' })}
                text={i18n.t('modal.disconnectPlatformDesc', { platform: 'Salesforce' })}
                confirmBtnText={i18n.t('common.disconnect')}
                cancelBtnText={i18n.t('common.cancel')}
                onConfirm={disconnectFromSalesforce}
                onCancel={() => setOpenDisconnectModal(false)}
                loading={loadingDisconnect}
            />
        </SettingsBox>
    );
};

export default SalesforceConnectForm;