import React from 'react';
import styles from './btn-container.module.scss';
import i18n from '../../../translations/i18n';
import { CircularProgress } from '@mui/material';

interface BtnContainerProps {
    onCancel: () => void;
    onSave: (e: React.MouseEvent<HTMLButtonElement>) => void;
    themeColor?: string;
    loading: boolean;
    isDisabled?: boolean;
    saveBtnText?: string;
    sx?: {cancel?: string, save?: string};
}

const BtnContainer: React.FC<BtnContainerProps> = ({
    onCancel, onSave, loading, isDisabled, saveBtnText, sx
}) => {
    const saveText = saveBtnText ? saveBtnText : i18n.t('common.save');
    const cancelStyle = sx?.cancel ? `${sx.cancel} ${styles.cancelBtn}` : styles.cancelBtn;
    const saveStyle = sx?.save ? `${sx.save} ${styles.saveBtn}` : styles.saveBtn;

    return (
        <div className={styles.btnContainer}>
            <button className={cancelStyle} onClick={onCancel}>
                {i18n.t('common.cancel')}
            </button>
            {loading
                ? <div className={styles.saveBtn}> <CircularProgress /> </div>
                : <button
                    className={saveStyle}
                    onClick={onSave}
                    style={isDisabled ? {opacity: 0.5} : undefined}
                    disabled={isDisabled}
                >
                    {saveText}
                </button>
            }
        </div>
    );
};

export default BtnContainer;