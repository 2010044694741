import React, {FC, useEffect, useState} from 'react';
import i18n from '../../../../translations/i18n';
import SettingsBox from '../../SettingsBox';
import {Input} from '../../../common/input/Input';
import BtnContainer from '../../../common/button/BtnContainer';
import {notEmpty} from '../../../../utils/common';
import {useDebouncedCallback} from 'use-debounce';
import {API_CALL_LONG_DEBOUNCE_TIME_IN_MS, SECRET_HIDDEN} from '../../../../constants/common';
import styles from '../salesforce/salesforce-connect-form.module.scss';
import { ReactComponent as EyeIcon } from '../../../../assets/icons/eye/eye.svg';
import { ReactComponent as EyeOffIcon } from '../../../../assets/icons/eye/eye-off.svg';
import { ReactComponent as WarningIconBlue } from '../../../../assets/icons/warning/warning-icon-blue.svg';
import { ReactComponent as WarningIcon } from '../../../../assets/icons/warning/warning.svg';
import {PASSWORD, TEXT} from '../../../../types/input';
import InfoModal from '../../../common/infoModal/InfoModal';
import useHubSpotFormController from './useHubSpotFormController';
import {isHidden} from '../integrationUtils';
import {AuthResponseType} from '../../../../types/integration';
import CustomButton from '../../../common/button/CustomButton';
import {WarningModal} from '../../../common/warningModal/WarningModal';

interface HubSpotConnectFormProps {
    isHubSpotEnabled: boolean;
    onClose: () => void;
    reloadIntegrationStatus: () => void;
}

const HubSpotConnectForm: FC<HubSpotConnectFormProps> = ({
    isHubSpotEnabled, onClose, reloadIntegrationStatus
}) => {
    const {
        loading, loadingDisconnect, authResponseType, setAuthResponseType,
        saveHubSpotCredentialsApi, onHubspotDisconnect
    } = useHubSpotFormController(reloadIntegrationStatus);

    const [apiToken, setApiToken] = useState<string>('');
    const [showSecret, setShowSecret] = useState<boolean>(false);
    const [openDisconnectModal, setOpenDisconnectModal] = useState<boolean>(false);

    const isSaveEnabled = () => {
        const isNotEmpty = notEmpty(apiToken);
        if (!isNotEmpty) return false;

        return !isHidden(apiToken);
    };

    const handleCancelChanges = () => {
        setApiToken('');
        onClose();
    };

    const handleSaveChanges = () => isSaveEnabled() && saveHubSpotCredentialsApi(apiToken);

    const handleSaveChangesDebounced = useDebouncedCallback(() => {
        return handleSaveChanges();
    }, API_CALL_LONG_DEBOUNCE_TIME_IN_MS);

    const getAuthResponseText = (type: AuthResponseType | undefined) => {
        if (!type) return '';
        if (type === 'success') return i18n.t('salesforce.authenticationSuccess');
        if (type === 'authError') return i18n.t('hubspot.authenticationFailed');
        if (type === 'serverError') return i18n.t('messages.internalServerError');
        return '';
    };

    const disconnectFromHubSpot = () => {
        onHubspotDisconnect()
            .then(() => {
                setApiToken('');
                setOpenDisconnectModal(false);
            });
    };

    useEffect(() => {
        isHubSpotEnabled && setApiToken(SECRET_HIDDEN);
    }, [isHubSpotEnabled]);

    return (
        <SettingsBox
            title={i18n.t('hubspot.hubspot')}
            goBack={handleCancelChanges}
            subtitleElement={isHubSpotEnabled &&
                <div className={styles.connected}>
                    {i18n.t('common.connected')}
                </div>
            }
        >
            <div className={styles.desc}>
                <div className={styles.warnIcon}>
                    <WarningIconBlue />
                </div>
                {i18n.t('hubspot.loginToHubspot')}
            </div>
            <Input
                label={i18n.t('hubspot.apiToken')}
                icon={showSecret ? <EyeIcon /> : <EyeOffIcon />}
                value={apiToken}
                type={showSecret ? TEXT : PASSWORD}
                placeholder={'***********'}
                onChange={(e) => setApiToken(e.target.value)}
                onIconPress={() => setShowSecret(prevState => !prevState)}
                className={styles.input}
                required
            />
            {isHubSpotEnabled &&
                <CustomButton
                    text={i18n.t('common.disconnect')}
                    onClick={() => setOpenDisconnectModal(true)}
                    sx={styles.disconnectBtn}
                />
            }
            <BtnContainer
                onCancel={handleCancelChanges}
                onSave={handleSaveChangesDebounced}
                loading={loading}
                isDisabled={!isSaveEnabled()}
                sx={{cancel: styles.cancelBtn}}
            />
            <InfoModal
                isOpen={authResponseType != undefined}
                onClose={() => setAuthResponseType(undefined)}
                type={authResponseType === 'success' ? 'success' : 'error'}
                title={i18n.t('common.authentication')}
                text={getAuthResponseText(authResponseType)}
            />
            <WarningModal
                isOpen={openDisconnectModal}
                icon={<WarningIcon />}
                title={i18n.t('modal.disconnectPlatformTitle', { platform: 'HubSpot' })}
                text={i18n.t('modal.disconnectPlatformDesc', { platform: 'HubSpot' })}
                confirmBtnText={i18n.t('common.disconnect')}
                cancelBtnText={i18n.t('common.cancel')}
                onConfirm={disconnectFromHubSpot}
                onCancel={() => setOpenDisconnectModal(false)}
                loading={loadingDisconnect}
            />
        </SettingsBox>
    );
};

export default HubSpotConnectForm;