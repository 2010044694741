export const FRENCH = {
    analytics: {
        country: 'Pays',
        previousPeriod: 'par rapport à la période précédente',
        users: 'Utiliateurs'
    },
    header: {
        accountSetting: 'Paramètre du compte',
        accountSettings: 'Paramètres du compte',
        analytics: 'Analytiques',
        changelog: 'Journal des modifications',
        companyCards: 'Cartes d\'entreprise',
        personalCards: 'Cartes personnelles',
        profilePage: 'Profil',
        walletCard: 'Carte de portefeuille',
        settings: 'Paramètres',
        signedInAs: 'Connecté en tant que',
        signOut: 'Se déconnecter',
        switchToBusinessAccount: 'Passer au compte professionnel',
        visitWebsite: 'Visitez Web',
        youAreEditing: 'Vous modifiez des cartes {{noofCards}}'
    },
    common: {
        about: 'À propos',
        accepted: 'Accepté',
        actions: 'Actes',
        activated: 'Activé',
        addMember: 'Ajouter un membre',
        admin: 'Gestionnaire',
        admins: 'administrateurs',
        appointmentBooking: 'Prise de rendez-vous',
        appearance: 'Apparence',
        allCards: 'Toutes les cartes',
        articles: 'articles',
        assign: 'Attribuer',
        assignCard: 'Attribuer une carte',
        assignCardTo: 'Attribuer une carte à',
        authentication: 'Authentification',
        bulkActions: 'Actions en masse',
        cancel: 'Annuler',
        cancelInvitation : 'Annuler l\'invitation',
        cardAssignedTo: 'Carte attribuée à',
        cardsSelected: 'Cartes sélectionnées',
        cardSelectedCapital: 'Carte sélectionnées',
        cardsSelectedCapital: 'Cartes Sélectionnées',
        cardViews: 'Vues de cartes',
        categories: 'Catégories',
        changeCardOwner: 'Changer le propriétaire de la carte',
        changePassword: 'Changer le mot de passe',
        changesSaved: 'Enregistrées',
        close: 'Fermer',
        companyCards: 'Cartes d\'entreprise',
        companyLinkedin: 'LinkedIn de l\'entreprise',
        contents: 'CONTENU',
        personalLinkedin: 'LinkedIn personnel',
        companyLogo: 'logo d\'entreprise',
        companyMembers: 'Membres de l\'entreprise',
        companyPage: 'Page d\'entreprise',
        confirmPassword: 'Confirmer le mot de passe',
        connect: 'Connecter',
        connected: 'Connecté',
        contact: 'Contact',
        contactUs: 'Contactez-nous',
        contacts: 'Contacts',
        contactSaved: 'Contact enregistré',
        copy: 'Copie',
        copied: 'Copié!',
        currentPassword: 'Mot de passe actuel',
        customLink: 'Lien personnalisé',
        dataAndPrivacy: 'Données et confidentialité',
        delete: 'Supprimer',
        deleteCard: 'Supprimer la carte',
        deleteContact: 'Supprimer le contact',
        deletePermanently: 'Supprimer définitivement',
        discard: 'Annuler',
        disconnect: 'Déconnecter',
        dismiss: 'Rejeter',
        download: 'Télécharger',
        downloadInstructions: 'Instructions de téléchargement',
        edit: 'Modifier',
        editCard: 'Modifier la carte',
        editCards: 'Modifier les cartes',
        editContact: 'Modifier le contact',
        editPermissions: 'Modifier les permissions',
        email: 'Email',
        emailDash: 'E-mail',
        emailAddress: 'Adresse e-mail',
        enabled: 'Activé',
        exchangeContact: 'Contact d\'échange',
        expired: 'Expiré',
        export: 'Exporter',
        facebook: 'Facebook',
        featuresAndImprovements: 'Fonctionnalités et améliorations que nous avons récemment déployées.',
        general: 'Généralités',
        github: 'GitHub',
        grid: 'Grille',
        groupPerformance: 'Performance de Groupe',
        helpDesk: "Service d'assistance",
        howCanWeHelp: 'Comment pouvons-nous vous aider?',
        inactive: 'Désactiver',
        instagram: 'Instagram',
        integrations: 'Intégrations',
        inviteAs: 'Inviter comme',
        inviteNewMember: 'Inviter un nouveau membre',
        language: 'Langue',
        lastUpdate: 'Mis à jour le ',
        leave: 'Partir',
        links: 'Liens',
        linkedin: 'LinkedIn',
        linkedinCompany: 'LinkedIn (Entreprise)',
        linkedinPersonal: 'LinkedIn (Personnel)',
        linkedinProfile: 'Profil LinkedIn',
        list: 'Liste',
        managedBy: 'Géré par',
        markAllAsRead: 'Marquer tout comme lu',
        member: 'Membre',
        message: 'Message',
        more: 'Plus',
        move: 'Se déplacer',
        moveCardsTo: 'Déplacez les cartes vers',
        moveToCompany: 'Déplacer vers cartes d\'entreprise',
        moveToMyCards: 'Déplacer vers Cartes personnelles',
        personalCards: 'Cartes personnelles',
        name: 'Nom',
        newContact: 'Nouveau Contact',
        newPassword: 'Nouveau mot de passe',
        noFileChosen: 'Aucun fichier choisi',
        notActivated: 'Non activé',
        notes: 'Remarques',
        notifications: 'Notifications',
        optional: 'optionnel',
        ok: 'Ok',
        or: 'ou',
        other: 'Autre',
        others: 'Autres',
        owner: 'Propriétaire',
        owners: 'propriétaires',
        password: 'Mot de passe',
        pending: 'En attente',
        permissions: 'La Permission',
        phone: 'Téléphone',
        pinterest: 'Pinterest',
        previewCard: 'Aperçu de la carte',
        proceed: 'Procéder',
        profilePicture: 'Photo de profil',
        pleaseSelectOption: 'Veuillez sélectionner une option',
        qrCode: 'QR code',
        recentActivities: 'Activités récentes',
        reddit: 'Reddit',
        refreshPage: 'Veuillez rafraîchir la page pour continuer',
        reload: 'Recharger',
        remove: 'Supprimer',
        removeMember: 'Supprimer le membre',
        request: 'Demande',
        resendInvitation: 'Renvoyer l\'invitation',
        report: 'Signaler ',
        role: 'Rôle',
        roles: 'Rôles',
        save: 'Enregistrer',
        saveChanges: 'Enregistrer les modifications',
        saveContact: 'Enregistrer le contact',
        search: 'Rechercher',
        selectAll: 'Tout sélectionner',
        selectFile: 'Sélectionner un fichier à importer',
        selectOption: 'Sélectionner une option',
        selectPlatform: 'Sélectionner une plateforme',
        send: 'Envoyer',
        sendEmail: 'Envoyer email',
        sent: 'Envoyée',
        sessionExpired: 'Session expirée',
        setInactive: 'Définir comme inactif',
        share: 'Partager',
        shareCard: 'Partager la carte',
        snapchat: 'Snapchat',
        stay: 'Rester',
        status: 'Statut',
        subject: 'Objet',
        subjectLine: 'Ligne d\'objet',
        submit: 'Soumettre',
        support: 'Soutien',
        supportedFormat: 'Formats pris en charge : jpeg, png, gif, pdf',
        stillNeedHelp: "Besoin d'aide supplémentaire ?",
        takeAphoto: 'Prendre une photo',
        threads: 'Threads',
        today: 'Aujourd\'hui',
        topCards: 'Meilleures Cartes',
        tiktok: 'Tiktok',
        title: 'Titre',
        to: 'À',
        toCapital: 'À',
        uniqueUsers: 'Utilisateurs uniques',
        unnamed: 'Anonyme',
        unsavedChanges: 'Modifications non enregistrées',
        unsavedChangesText: 'On dirait que tu as changé quelque chose. Si vous quittez avant d\'enregistrer, vos modifications seront perdues.',
        user: 'Utilisateur',
        users: 'utilisateurs',
        usersByCountries: 'Utilisateurs par Pays',
        view: 'Vue',
        viewAll: 'Voir tout',
        viewMode: 'Mode d\'affichage',
        yourAccount: 'Votre compte',
        website: 'Site web',
        whatsapp: 'WhatsApp',
        x: 'X',
        yes: 'Oui',
        yourBusinessCard: 'Votre carte de visite',
        youtube: 'YouTube'
    },
    contact: {
        description1: 'Avez-vous des questions ou besoin de conseils?',
        description2: 'Nous serions ravis de vous aider!',
        hello: 'Bonjour!',
        liveChat: 'Chat en direct',
        helpDeskDescription: 'Accéder au Portail de Support'
    },
    integration: {
        allSynced: 'Tous synchronisés!',
        syncingContacts: 'Synchronisation de {{count}} contacts',
        syncNow: 'Sync maintenant',
        syncNewContacts: 'Sync {{count}} nouveaux contacts',
    },
    modal: {
        addUserRoleText: 'Etes-vous sûr de vouloir attribuer le rôle {{role}} à {{username}}?',
        areYouSure: 'Êtes-vous sûr?',
        areYouSureDeleteText: 'Etes-vous sûr de vouloir supprimer le contact?',
        changeCardOwnerDesc: 'En cas de changement de propriétaire, vous perdrez l\'accès à la gestion et à la consultation de ce profil',
        deleteAccountConfirm: 'Oui, supprimer mon compte',
        deleteAccountText: 'Êtes-vous certain de vouloir supprimer définitivement votre compte iCards ? Veuillez noter qu\'une fois supprimé, il n\'y a aucun moyen de restaurer le compte ou ses données.',
        deleteText: 'Cette action est irréversible. Si vous supprimez cette carte, elle sera perdue pour toujours. Tout lien ou QR code associé à cette carte ne fonctionnera plus.',
        disable2faTitle: 'Désactiver l’authentification à deux facteurs',
        disable2faDesc: 'Voulez-vous désactiver l’authentification à deux facteurs?',
        disconnectPlatformTitle: 'Se déconnecter de {{platform}}',
        disconnectPlatformDesc: 'Vous êtes sur le point de déconnecter {{platform}} et iCards. Une fois la déconnexion effectuée, nous ne supprimerons aucune des données déjà enregistrées dans {{platform}}. Cependant, les futures mises à jour ne seront plus synchronisées entre les deux systèmes.',
        disconnectPlatformSuccess: 'La connexion à {{platform}} a été déconnectée avec succès.',
        enterCompanyName: 'Entrez le nom de l\'entreprise',
        hiThere: 'Salut 👋',
        howCanIhelpYou: 'Comment puis-je t\'aider',
        iCardsTeam : 'L\'équipe iCards',
        iCardsTeamSubTitle : 'Répond généralement dans la journée',
        lostPhysicalCard: 'Avez vous perdu votre carte physique ?',
        lostPhysicalCardText: 'Si votre carte a été perdue ou volée, pour la protection de vos données, vous avez la possibilité de désactiver le profil utilisé pour cette carte. Si votre carte a été endommagée, vous pouvez commander une carte de remplacement avec le lien vers le même profil, et toutes vos informations resteront inchangées.',
        orderNewCardText: 'Commandez une carte de remplacement avec le lien vers le même profil et toutes vos informations resteront inchangées.',
        permissionModalText: 'Définir ce que les {{role}} de l\'entreprise peuvent modifier.',
        removeCompanyInvitationTitle: "Supprimer l'invitation de l'entreprise",
        removeCompanyInvitationDesc: "L'invitation de l'entreprise pour {{userEmail}} sera supprimée.",
        removeCompanyMemberTitle: 'Supprimer le membre de l\'entreprise',
        removeCompanyMemberDesc: '{{name}} ne peut être supprimé que si aucune carte d\'entreprise ne lui est attribuée. Toutes iCards que {{name}} possède seront également retirées de l\'entreprise.',
        setInactiveText : 'Cette option désactivera le lien vers votre profil et vous pourrez commander une carte de remplacement avec le nouveau profil.',
        startChat : 'Démarrer la discussion',
        setup2faTitle: "Configurer l'authentification à deux facteurs",
        setup2faDesc: "Souhaitez-vous procéder à la configuration de l'authentification à deux facteurs? Vous devrez terminer la configuration avant de pouvoir la désactiver à nouveau."
    },
    myCards: {
        clickBtnToCreateCard: 'Cliquez sur le bouton ci-dessous pour créer votre première carte de visite numérique',
        newCard: 'Nouvelle carte',
        orderNewCard: 'Commander une nouvelle carte',
        setInactiveText: 'Désactiver si cette carte a été perdue, volée  ou si elle n\'est plus utilisée. Cette option désactivera le lien vers votre profil.',
        setInactiveText2: 'Désactiver cette carte si vous souhaitez qu\'elle soit invisible.',
        youDontHaveCards: 'Vous n\'avez aucune carte'
    },
    notification: {
        cardActivatedUserTitle: 'Carte activée avec succès !',
        cardActivatedUserDesc: 'Votre carte a été activée et est maintenant prête à l\'emploi. Veuillez la garder en sécurité.',
        cardActivatedOwnerTitle: 'Carte activée avec succès !',
        cardActivatedOwnerDesc: 'Votre carte a été activée. Vous pouvez maintenant la déplacer vers les cartes de l\'entreprise ou changer son propriétaire.',
        cardAssignedTitle: 'Carte assignée à votre compte',
        cardAssignedDesc: 'Une carte a été assignée avec succès à votre compte. Vous pouvez maintenant la gérer via les paramètres de votre compte.',
        cardOwnerTransferredTitle: 'Propriété de la carte transférée',
        cardOwnerTransferredDesc: 'La propriété de votre carte a été transférée à un nouveau propriétaire. Veuillez contacter le support si vous avez des questions ou des préoccupations.',
        cardOwnerReceivedTitle: 'Propriété de la carte reçue',
        cardOwnerReceivedDesc: 'Vous êtes devenu propriétaire d\'une carte. Vous pouvez maintenant la gérer via les paramètres de votre compte.',
        companyRoleAssignedTitle: 'Rôle dans l\'entreprise attribué!',
        companyRoleAssignedDesc: "Vous avez été affecté(e) au nouveau rôle de {{role}} pour l'entreprise {{company}}",
        newFeatureTitle: 'Nouvelle fonctionnalité disponible.',
        newFeatureAdded: 'Une nouvelle fonctionnalité vient d\'être ajoutée pour améliorer votre expérience. Découvrez-la dès maintenant!',
        newFeaturesAdded: 'Nous avons amélioré notre plateforme! Découvrez dès aujourd\'hui les nouvelles fonctionnalités.',
        noNewNotifications: "Vous n'avez aucune nouvelle notification pour le moment"
    },
    labels: {
        buttonBackgroundColor: 'Couleur d\'arrière-plan du bouton',
        buttonTextColor: 'Couleur du texte du bouton',
        cardBackgroundColor: 'Couleur d\'arrière-plan de la carte',
        cardTextColor: 'Couleur du texte de la carte',
        city: 'Ville',
        company: 'Entreprise',
        companyBgColor: 'Couleur de fond de l\'entreprise',
        companyName: 'Nom de l\'entreprise',
        companyTextColor: 'Couleur du texte de l\'entreprise',
        country: 'Pays',
        cover: 'Couverture',
        coverImage: 'Image de couverture',
        coverBackgroundColor: 'Couleur d\'arrière-plan de la couverture',
        createCompany: 'Créer une entreprise',
        createNewCompany: 'Créer une nouvelle entreprise',
        department: 'Département',
        filterBy: 'Filtrer par',
        firstName: 'Prénom',
        fullname: 'Nom complet',
        iconBackgroundColor: 'Couleur d\'arrière-plan de l\'icône',
        iconsColor: 'Couleur des icônes',
        lastName: 'Nom de famille',
        language: 'Langue',
        pendingUserHasNotAcceptYet: 'En attente: attendre que l\'utilisateur active le compte',
        position: 'Poste',
        searchNameOrEmail: 'Rechercher par nom ou par email',
        streetAdress: 'Adresse',
        stepsToTeproduceTheIssue: 'Étapes pour reproduire le problème',
        zipPostal: 'Code postal'
    },
    messages: {
        companyInvitationSent: 'L\'invitation à l\'entreprise a été envoyée.',
        companyInvitationRemoved: 'L\'invitation de l\'entreprise a été supprimée avec succès.',
        companyMemberRemoved: 'Le membre de l\'entreprise a été supprimé avec succès.',
        companyWasSuccessfullyCreated: 'L\'entreprise a été créée avec succès',
        contactSuccessfullyRemoved: 'Contact enregistré avec succès supprimé',
        contactSuccessfullySaved: 'Contact enregistré avec succès',
        contactSuccessfullySynced: 'Contacts synchronisés avec succès',
        contactSyncFailed: 'Contact synchronization failed.',
        emailSentSuccess: 'Votre e-mail a été envoyé avec succès!',
        emailSentFailed: 'Échec de l’envoi de votre e-mail. Veuillez réessayer plus tard.',
        internalServerError: 'Erreur interne du serveur',
        noIcardsFound: 'Aucune iCards trouvée',
        noCompanyMemberFound: 'Aucun membre de l\'entreprise trouvé',
        noContactsFound: 'Aucun contact trouvé',
        noCategoryFound: 'Aucune catégorie trouvé',
        passwordHasBeenChanged: 'Le mot de passe a été modifié.',
        passwordsDoNotMatch: 'Le mot de passe et la confirmation du mot de passe ne correspondent pas.',
        profilesSuccessfullyUpdated: '{{noofCards}} profils mis à jour avec succès',
        profileWasSuccessfullyAssigned: 'Le profil a été attribué avec succès.',
        selectAtLeastOneItem: 'Veuillez sélectionner au moins un élément pour effectuer des actions en masse.',
        unableToRemoveCompanyMember: 'Impossible de retirer le membre de l\'entreprise car il est assigné à des profils de cartes d\'entreprise.'
    },
    notFoundPage: {
        description: 'Nous n’avons pas trouvé la page que vous recherchez. Elle a peut-être été déplacée, renommée ou n’existe plus.',
        goBackToHomepage: 'Retour à la page d’accueil',
        title: 'Page non trouvée'
    },
    policy: {
        iHaveRead: 'J\'ai lu et j\'accepte le',
    },
    profile: {
        colors: {
            custom: 'Personnalisé',
            default: 'Par défaut',
            green: 'Vert',
            purple: 'Violet',
            red: 'Rouge',
            yellow: 'Jaune'
        },
        error: {
            fileTooLarge: 'Le fichier est trop volumineux. Veuillez télécharger un fichier de moins de 5 Mo.',
            invalidEmailAddress: 'Adresse e-mail invalide',
            invalidFileType: 'Type de fichier invalide. Veuillez télécharger un fichier PNG ou JPEG.',
            specialCharacters: 'Vous ne devez pas utiliser de caractères spéciaux'
        },
        general: {
            changeCover: 'Changer la couverture',
            changePic: 'Changer l\'image',
            dragAndDrop: 'ou faites glisser et déposez',
            noFileChosen: 'Aucun fichier sélectionné',
            pngJpgGif: 'PNG, JPG, GIF jusqu\'à 10 Mo',
            upload: 'Télécharger',
            uploadFile: 'Télécharger un fichier',
            uploadPic: 'Télécharger une image'
        },
        links: {
            addLink: 'Ajouter un lien'
        },
        contact: {
            addField: 'Ajouter un champ'
        },
        noContentAdded: 'n\'a pas encore ajouté de contenu',
        poweredBy: 'Propulsé par :'
    },
    hubspot: {
        authenticationFailed: 'Échec de l\'authentification ! Veuillez vérifier votre jeton API.',
        apiToken: 'Jeton API',
        description: 'Plateforme client alimentée par l\'IA, avec tous les logiciels, intégrations et ressources nécessaires pour connecter votre marketing, vos ventes et votre service client.',
        hubspot: 'HubSpot',
        loginToHubspot: 'Veuillez vous connecter à votre compte HubSpot, créer une application privée et saisir le jeton API.'
    },
    salesforce: {
        authenticationSuccess: 'Authentification réussie ! Vous êtes prêt à explorer les fonctionnalités d\'intégration et à optimiser vos opérations.',
        authenticationFailed: 'Échec de l\'authentification ! Veuillez vérifier vos identifiants.',
        consumerKey: 'Clé consommateur',
        consumerSecret: 'Secret consommateur',
        description: 'Logiciel cloud conçu pour aider les entreprises à trouver plus de prospects et à conclure davantage d\'affaires.',
        enableIntegration: 'Activez l’intégration Salesforce ou HubSpot pour maintenir vos contacts à jour et connectés.',
        instanceUrl: 'URL de l\'instance Salesforce',
        loginToSalesforce: 'Veuillez vous connecter à votre compte Salesforce, créer une nouvelle application connectée et remplir l\'URL de rappel.',
        salesforce: 'Salesforce'
    },
    selector: {
        last7days: 'Les 7 derniers jours',
        last14days: 'Les 14 derniers jours',
        last30days: 'Les 30 derniers jours',
        totalViews: 'Vues totales'
    },
    settings: {
        deleteYourAccount: 'Supprimer votre compte',
        featureRequest: 'Demande de fonctionnalité',
        permanentlyDeleteYourAccount: 'Supprimer votre compte définitivement',
        privacyPolicy: 'Politique de confidentialité',
        reportBug: 'Signaler un bug',
        reportBugDesc: 'Un problème est survenu ? Prévenez-nous !',
        submitRequest: 'Soumettre une demande',
        supportContactDesc: 'N\'hésitez pas à contacter notre aimable équipe de support.',
        supportFeatureDesc: 'Avez-vous des suggestions ? Faites-nous savoir comment nous pourrions nous améliorer.',
        supportFeatureContactDesc: 'N\'hésitez pas à nous indiquer ce que vous souhaitez ! Elle pourrait déjà figurer dans notre plan de développement.',
        supportFeatureContactPlaceholder: 'Décrivez votre demande',
        supportFeatureContactTitle: 'Vous recherchez une fonctionnalité particulière?',
        supportFeedback: 'Support & Retour',
        termsOfService: 'Conditions d\'utilisation',
        termsAndConditions: 'Conditions d\'utilisation',
        titleDesc: 'Que recherchez-vous ?',
        twofaEnableBtn: 'Activer 2FA',
        twofaDisableBtn: 'Désactiver 2FA',
        twofaDesc: 'L\'authentification à deux facteurs protège votre compte en ajoutant une étape de sécurité supplémentaire lorsque vous vous connectez.',
        twofaTitle: 'Authentification à deux facteurs (2FA)'
    },
    activate: {
        assignProfile: 'Voulez-vous activer votre iCard {{uuid}} pour ce compte?',
        failedToActivateProfile: 'Échec de l\'activation de votre iCard',
        profileActivation: 'Activez votre iCard',
        welcomeMessage1: 'Bienvenue sur iCards',
        welcomeMessage2: 'Votre iCard n\'est pas encore activée. Veuillez cliquer sur le bouton ci-dessous pour l\'activer',
        welcomeMessage3: 'Si vous avez déjà un compte, vous devrez vous connecter, sinon vous devrez vous inscrire',
        welcomeActivateButton: 'Activez votre iCard',
        yesActivate: 'Oui, activez!',
        yourProfileIsActivated: 'Votre iCard a été activé avec succès!'
    }
};