import {getExternalUrl} from '../../../utils/common';
import {getEncodedAndResizedProfilePhoto} from '../../../utils/image';
import {ExchangeContactData} from '../../../types/exchangeContacts';
import {isAndroid} from '../../../utils/device';


export const createVCardFromContact= (
    contactData: ExchangeContactData,
    base64Photo: string,
) => {
    const isAndroidDevice = isAndroid();

    const mobilePhoneNumber = contactData.phone?.trim();
    const userEmail= contactData.email?.trim();
    const company = contactData.company?.trim();
    const note = contactData.notes?.trim()?.replace(/\n/g, '\\n');
    const fullName = `${contactData.firstName?.trim()} ${contactData.lastName?.trim()}`;

    const contact = {
        name: fullName,
        mobilePhone: mobilePhoneNumber ? mobilePhoneNumber : '',
        email: userEmail,
        company: company,
        note: note ?? '',
        website: getExternalUrl(contactData.website)
    };

    return (
        `BEGIN:VCARD
VERSION:3.0
N:${contact.name?.replace(/\s+/g, ';').split(';').reverse().join(';')};;;
FN:${contact.name}
NOTE:${contact.note}
ORG:${contact.company}
${contact.mobilePhone ? `TEL;TYPE=CELL,voice:${contact.mobilePhone}` : ''}
EMAIL;TYPE=${isAndroidDevice ? 'X-email' : 'INTERNET'}:${contact.email}
URL:${contact.website}
PHOTO;ENCODING=b;TYPE=PNG:${base64Photo}
END:VCARD`
    );
};

export const saveContactToPhone = async (
    contactData: ExchangeContactData,
    profileUrl?: string
) => {
    if (!contactData) return;

    const base64Photo = profileUrl ? await getEncodedAndResizedProfilePhoto(profileUrl, 200, 200) : '';
    const cleanedBase64Photo = base64Photo.replace(/\s/g, '');

    const vcard = createVCardFromContact(
        contactData,
        cleanedBase64Photo
    );

    const blob = new Blob([vcard], { type: 'text/vcard' });
    const url = URL.createObjectURL(blob);

    const firstName = contactData.firstName?.trim();
    const newLink = document.createElement('a');
    newLink.download = `${firstName}.vcf`;
    newLink.textContent = firstName;
    newLink.href = url;

    setTimeout(() => newLink.click(), 0);
};